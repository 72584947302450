"use client";

import { getAssociatedInvitation } from "@/app/(client)/(with-privy)/(logged-in-only)/actions";
import { usePrivy, User } from "@privy-io/react-auth";
import { Loader } from "lucide-react";
import Link from "next/link";
import { useEffect, useState } from "react";
import { Invitation } from "@prisma/client";
import { useRouter } from "next/navigation";
import LoginButton from "./login-button";

/*
if (wasAlreadyAuthenticated) {
  try {
    const invitation = await getUsedInvitation(user!.id);

    if (!isNewUser && invitation) {
      const { code } = invitation;

      // Case 4)
      setLoginButton({
        title: "Profile",
        url: `/profile?invitation=${code}`,
      });
    } else {
      // Case 3)
      setLoginButton({ title: "Login", url: "/not-member" });
    }
  } catch (error) {
    // console.error(error);

    // Case 3) too
    setLoginButton({ title: "Login", url: "/not-member" });
  }
} else {
  // You've been logged in for the first time (whether you've created a WM account before or not)

  try {
    const invitation = await getUsedInvitation(user!.id);

    if (!isNewUser && invitation) {
      const { code } = invitation;

      // Case 2)
      router.push(`/profile?invitation=${code}`);
    } else {
      // Case 1)
      router.push(`/not-member`);
    }
  } catch (error) {
    console.error(error);
    router.push(`/not-member`);
  }
}
*/

export default function Drawer() {
  const router = useRouter();
  const { ready, authenticated, user } = usePrivy();
  const invitation = useInvitation(user);

  useEffect(() => {
    if (
      "error" in invitation ||
      (invitation.status === "success" &&
        invitation.data.invitation_status !== "USED")
    ) {
      router.push("/not-member");
    }
  }, [invitation, router]);

  if (!ready || invitation.status === "pending") {
    return (
      <div className="space-y-6">
        {/* This creates 24px gap (spacing) */}

        <div
          className="flex h-[54px] w-full items-center justify-center rounded-lg
            bg-gradient-to-r from-white via-gray-50 to-white"
        >
          <Loader
            className="animate-spin"
            size={24}
            strokeWidth={2}
            aria-hidden="true"
          />
        </div>
      </div>
    );
  }

  if (
    ready &&
    authenticated &&
    invitation.status === "success" &&
    invitation.data.invitation_status === "USED"
  ) {
    return (
      <>
        <Link
          href={`/profile?invitation=${invitation.data.code}`}
          className="inline-flex h-[54px] w-full items-center justify-center
            rounded-lg bg-white text-lg font-bold text-mavins-grey
            hover:bg-white"
        >
          Go to your profile
        </Link>
      </>
    );
  }

  if (ready && !authenticated) {
    return (
      <>
        <LoginButton />
        {/* <Link
          href={`/join-waiting-list`}
          className="inline-flex h-[54px] w-full items-center justify-center
            rounded-lg border border-white bg-transparent text-lg font-bold
            text-white"
        >
          Join waiting list
        </Link> */}
      </>
    );
  }

  // if (ready && authenticated && invitation.status === "error") {
  //   throw new Error(
  //     `Unauthorized access. User with email ${user?.email?.address} was not invited to Wildmavins. If you think this is a mistake, please contact contact@wildmavins.com.`,
  //   );
  // }
}

export function useInvitation(privyUser: User | null) {
  const [result, setResult] = useState<
    | { status: "inactive" }
    | { status: "pending" }
    | { status: "success"; data: Invitation }
    | { status: "error"; error: Error }
  >({ status: "inactive" });

  useEffect(() => {
    if (!privyUser) {
      return;
    }

    async function fetchInvitation() {
      try {
        setResult({ status: "pending" });
        const data = await getAssociatedInvitation(privyUser!.id);
        setResult({ status: "success", data });
      } catch (error) {
        setResult({ status: "error", error: error as Error });
      }
    }

    fetchInvitation();
  }, [privyUser]);

  return result;
}
