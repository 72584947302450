"use client";

import { Button } from "@/components/ui/button";
import { useLogin } from "@privy-io/react-auth";
import LogRocket from "logrocket";
import { useCallback } from "react";

export default function LoginButton() {
  const { login } = useLogin({
    onComplete: async (user) => {
      // TODO: add e-mail too?
      LogRocket.identify(user.id);
    },
  });

  const handleClick = useCallback(() => {
    login({
      disableSignup: true,
    });
  }, [login]);

  return (
    <Button
      onClick={handleClick}
      className="inline-flex h-[54px] w-full items-center justify-center
        rounded-lg bg-white text-lg font-bold text-mavins-grey hover:bg-white"
    >
      Login
    </Button>
  );
}
